:root {
    --padding-contact-info-elemets: 1rem;
    --gap-main-grid: 1rem
}

.contact-background {
    position: fixed;
    width: 100%;
    top: 0;
    object-fit: contain;
    z-index: -1;
}

.contact-background img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0%;
}

.background-filter {
    height: 100vh;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, .2);
}

.contact-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: var(--gap-main-grid);
    height: calc(100vh - var(--nav-bar-height));
    padding: 3rem;
    padding-left: 8%;
    padding-right: 8%;

    grid-template-areas:
        "title email"
        "phone map"
        "cities map"
        "cities map";

}

.contact-main .contact-title {
    grid-area: title;
    display: flex;
    font-family: sans-serif;
    font-size: 2vw;
    text-align: center;
    align-items: center;
    justify-content: center;

}

.contact-main .contact-element {
    display: flex;
    font-family: var(--main-font-family);
    font-size: 1.8vw;
    justify-content: space-between;
    align-items: center;
    padding-left: 8%;
    padding-right: 8%;
}

.contact-main .cities {
    grid-area: cities;
    flex-direction: column;
    align-items: normal;
    justify-content: center;

}

.cities h3 {
    margin-bottom: 1rem;
}

.cities-list {

    display: grid;
    grid-template-columns: auto auto;
    font-size: 1.3vw;
    padding-left: 1.3vw;
}

.email {
    grid-area: email;
}

.phone {
    grid-area: phone;
}

.map-info {
    grid-area: mapinfo;
    justify-content: center;
}

.contact-main .map {
    padding: 0%;
    grid-area: map;
    position: relative;
    overflow: hidden;
}

.contact-main>div {
    background-color: var(--background-colour-bright-transparent);
    padding: var(--padding-contact-info-elemets);
    border-radius: var(--border-radius-general-elements);
}


.contact-main .map img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0%;
    filter: grayscale(.4);
}


.contact-element button {
    text-decoration: none;
    color: var(--font-color-dark-primary);
    border: none;
    border-color: transparent;
    background-color: transparent;
    border-radius: .7rem;
    font-size: 1.8rem;
    cursor: pointer;
    padding: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    /* makes text selectable */
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}



@media (max-width: 480px) {
    .contact-main {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: var(--gap-main-grid);
        padding: 3rem;
        padding-left: 8%;
        padding-right: 8%;
        height: 1fr;
        grid-template-areas:
            "title "
            "email"
            "phone"
            "cities"
            "map";
        margin-bottom: 5rem;
    }

    .contact-main .map img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: static;
        filter: grayscale(.4);
    }

    .contact-main .map {
        padding: 0%;
        grid-area: map;
        position: static;
        overflow: hidden;
        height: 10rem;
    }

    .contact-title h1 {
        font-size: 8vw;
    }

    .contact-element h3 {
        font-size: 4.5vw;
    }

    .contact-element button {
        font-size: 4.5vw;
    }

    .cities-list {
        font-size: 3.5vw;
    }
}