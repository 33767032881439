@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

:root {
    --primary-color: #5E78FC;
    --secondary-color: rgba(68, 168, 190, 1);
    --font-dark: rgb(77, 81, 86);
    --font-dark: rgb(77, 81, 86);
    --font-size-secondary-elements: 1.4vw;
    --font-size-footer: 1.2rem;
    --main-font-family: 'Roboto', sans-serif;
    --font-color-dark-primary: #0D0D0D;
    --font-color-dark-secondary: #262626;
    --border-radius-button: 0.8rem;
    --border-radius-general-elements: 2rem;
    --blur: blur(8px);
    --font-color-white-primary: #E6E6E6;
    --background-colour-dark-transparent: rgba(0, 0, 0, 0.2);
    --background-colour-bright-transparent: rgba(255, 255, 255, .6);
    /*
    --font-color-white-primary:#0D0D0D;
    --background-colour-dark-transparent:rgba(255, 255, 255, 0.5);
    --primary-color: #be5a44;
    */
}

* {
    margin: 0;
    box-sizing: border-box;
}

button {
    color: var(--font-color-dark-primary);
}