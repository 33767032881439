#footer {
    background-color: var(--background-colour-bright-transparent);
    backdrop-filter: var(--blur);
    padding-top: 2rem;
    padding-bottom: 1rem;
    display: grid;
    font-family: sans-serif;
    line-height: 150%;

    overflow-x: hidden;
    /*
    border-radius: var(--border-radius-general-elements) var(--border-radius-general-elements) 0 0;
    */
}

.footer-container button {
    background-color: transparent;
    border: none;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
    padding: 0%;
    font-size: 1rem;

    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.footer-container ul {
    padding-left: 1rem;
}

.footer-container li {
    text-align: left;


}

.footer-container {
    width: 84%;
    position: relative;
    left: 50%;
    transform: translate(-50%);

}

.footer-top {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 1rem;
    /* justify-items: start | center | end; */
    justify-content: space-between;
}

.footer-top div {
    display: grid;

    align-content: start;

    width: max-content;
}


.footer-title {
    font-weight: bold;
    margin-bottom: 1rem;
}

.footer-phone {
    margin-bottom: .5rem;
}

.line-mid-footer {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    border-bottom: 1px solid rgba(0, 0, 0, .25);
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.footer-bottom {
    display: grid;
    justify-content: center;

}

@media (max-width:1000px) {
    .footer-top {
        grid-template-columns: auto auto;
    }
}

@media (max-width:700px) {
    .footer-top {
        grid-template-columns: auto;
        justify-content: center;
    }
}