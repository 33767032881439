:root {
    --padding-horizontal-title-container: 4.5%;
    --padding-vertical-title-container: 2.5%;
    --border-radius-title-container: 2rem;
    --bottom-margin-text-container-elements: 2rem;
    --vertical-padding-main-lockup-button: 3%;
    --horizontal-padding-main-lockup-button: 6%;
    --gap-title-container: 10rem;
}

body {
    background-color: grey;
}

.top-image-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: -1;
    filter: brightness(0.8);

    background-color: grey;
}

.top-image-container img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: -2;
}

.top-image-container .index-hidden {
    filter: blur(4px);
    visibility: hidden;
    opacity: 0;

}


.index-visible {
    visibility: visible;
    opacity: 1;
    transition: 1s;
    object-position: bottom left;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.index-visible:nth-child(1) {
    animation-name: i1;
}

.index-visible:nth-child(2) {
    animation-name: i2;
}

.index-visible:nth-child(3) {
    animation-name: i3;
}

.index-visible:nth-child(4) {
    animation-name: i4;
}

@keyframes i1 {
    from {
        object-position: 40% 0%;
    }

    to {
        object-position: 60% 100%;
    }
}

@keyframes i2 {
    from {
        object-position: 20% 100%;
    }

    to {
        object-position: 40% 0%;
    }
}

@keyframes i3 {
    from {
        object-position: 70% 0%;
    }

    to {
        object-position: 90% 100%;
    }
}

@keyframes i4 {
    from {
        object-position: 40% 100%;
    }

    to {
        object-position: 60% 0%;
    }
}

@keyframes index-slide-center-bottomleft {
    from {
        object-position: 40% 0%;
    }

    to {
        object-position: 60% 100%;
    }
}

@keyframes index-slide-center-topright {
    from {
        object-position: 60% 100%;
    }

    to {
        object-position: 40% 0%;
    }
}

.text-containers-grid {
    display: grid;
    gap: 4rem;
    padding-top: calc(var(--nav-bar-height) + 5%);
    padding-bottom: 10%;
}

.text-containers-grid> :nth-child(odd) {
    position: relative;
    left: 3.5%;
    width: 40%;
    color: var(--font-color-dark-primary);
    font-family: sans-serif;
    display: grid;
    gap: var(--gap-title-container);
    padding-left: var(--padding-horizontal-title-container);
    padding-right: var(--padding-horizontal-title-container);
    padding-top: var(--padding-vertical-title-container);
    padding-bottom: var(--padding-vertical-title-container);
    border-radius: var(--border-radius-title-container);
    backdrop-filter: var(--blur);
    background-color: var(--background-colour-bright-transparent);
}

.text-containers-grid> :nth-child(even) {
    position: relative;
    left: 96.5%;
    transform: translateX(-100%);
    width: 40%;
    color: var(--font-color-dark-primary);
    font-family: sans-serif;
    display: grid;
    gap: var(--gap-title-container);
    padding-left: var(--padding-horizontal-title-container);
    padding-right: var(--padding-horizontal-title-container);
    padding-top: var(--padding-vertical-title-container);
    padding-bottom: var(--padding-vertical-title-container);
    border-radius: var(--border-radius-title-container);
    backdrop-filter: var(--blur);
    background-color: var(--background-colour-bright-transparent);
}

.transparent {
    display: none;
    opacity: 0;
}

.main-lockup>* {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.main-lockup h1 {
    font-size: 2.8vw;
    text-transform: uppercase;
    cursor: pointer;
}

.index-lockup>* {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.index-lockup h1 {
    font-size: 2.8vw;
    text-transform: uppercase;
    cursor: pointer;
}

.index-description {

    display: grid;
    gap: calc(var(--gap-title-container) / 1);
}

.index-description p {
    font-family: var(--main-font-family);
    font-size: var(--font-size-secondary-elements);
    line-height: 150%;
}

.index-description li {
    font-family: var(--main-font-family);
    font-size: var(--font-size-secondary-elements);
    line-height: 150%;
}

.main-lockup-button {
    border-color: transparent;
    background-color: var(--primary-color);
    padding-left: var(--horizontal-padding-main-lockup-button);
    padding-right: var(--horizontal-padding-main-lockup-button);
    padding-top: var(--vertical-padding-main-lockup-button);
    padding-bottom: var(--vertical-padding-main-lockup-button);
    /*  margin-left: calc(0rem - var(--horizontal-padding-main-lockup-button)); */
    color: var(--font-color-white-primary);
    font-family: var(--main-font-family);
    font-size: 1.3rem;
    text-align: center;
    cursor: pointer;
    width: fit-content;
    border-radius: var(--border-radius-button);
}

.main-lockup-button:hover {
    background-color: #506BFC;
}



@media (max-width: 480px) {
    .top-image-container {
        filter: brightness(0.8) blur(8px);
    }

    .text-containers-grid> :nth-child(n) {
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        color: var(--font-color-dark-primary);
        gap: var(--gap-title-container);
        padding: 2rem;
    }

    .text-containers-grid>*>h1 {
        font-size: 2rem;
        text-align: center;
    }

    .index-description>p {
        font-size: 1rem;
    }

    .index-description li {
        font-size: 1rem;
    }

    .index-description>button {
        font-size: 1rem;
    }
}














/* OLD CODE */
/*
.spine-container {
    position: fixed;
    top: 20%;
    height: 20%;
    left: 2%;
    z-index: 999;
}
.spine-container img {
    height: 100%;
}
.top-image-container .index-text-container {
    width: 84%;
    z-index: 0;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: var(--font-color-dark-primary);
    padding-left:var(--padding-horizontal-title-container);
    padding-right:var(--padding-horizontal-title-container);
    border-radius: var(--border-radius-title-container) var(--border-radius-title-container) 0 0;
    background-color: var(--background-colour-bright-transparent);
    backdrop-filter: var(--blur);
    padding-top: var(--padding-vertical-title-container);
    padding-bottom: var(--padding-vertical-title-container);
}
.appointment-button {
    background-color: var(--background-colour-dark-transparent);
    border-style: solid;
    border-color: white;
    padding: 1.2%;
    color: var(--font-color-dark-primary);
    font-family: var(--main-font-family);
    font-size: 1vw;
    height: fit-content;
    cursor: pointer;
    width: fit-content;
    border-radius: 4rem;
    backdrop-filter: blur(2px);
}

.appointment-button:hover {
    background-color: black;
    border-color: transparent;
    transition: 250ms;
}
*/