:root {
    --title-padding: 5%;
    --title-font-size: 3vw;
    --title-font-family: sans-serif;
    --description-font-family: sans-serif;
    --title-font-size: 3vw;
    --description-font-size: 1vw;
    --text-container-padding-vertical: 10%;
    --bottom-margin-title: 4vw;
    --font-size-secondary-elements: 1.1vw;
    --font-size-primary-elements: 3rem;
    --font-color-dark-primary: #0D0D0D;
    --font-color-dark-secondary: #262626;
    --font-color-white-primary: #E6E6E6;

    --gap-title-container: 3.2vh;
    --padding-horizontal-title-container: 4.5%;
    --padding-vertical-title-container: 2.5%;
    --border-radius-title-container: 2rem;
    --bottom-margin-text-container-elements: 2rem;

    --button-about-me-border-radius: 2rem;
    --button-about-me-horizontal-padding: 6%;
    --button-about-me-vertical-padding: 3%;
}

.about-me-main-container {
    height: 100vh;
    padding-top: var(--nav-bar-height);
}

.about-me-background {
    position: absolute;
    width: 100%;
    top: 0;
    object-fit: contain;
}

.about-me-background video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0%;
    z-index: -1;
}

.about-me-background img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0%;
    z-index: -1;
}

.background-filter {
    height: 100vh;
    background-color: rgba(255, 255, 255, .2);
    backdrop-filter: blur(8px);
}

.about-me-curves .video-filters {
    height: 100vh;
    width: 100%;
}

.about-me-mobile-container {
    display: none;
}

.about-me-text-wrap {
    position: relative;
    height: calc(100vh - var(--nav-bar-height));
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.about-me-text-container {
    position: relative;
    top: 50%;
    left: 3.5%;
    transform: translateY(-50%);
    width: 60%;
    color: var(--font-color-dark-primary);
    font-family: 'inter', sans-serif;
    display: grid;
    gap: var(--gap-title-container);
    padding-left: var(--padding-horizontal-title-container);
    padding-right: var(--padding-horizontal-title-container);
    padding-top: calc(var(--padding-vertical-title-container) *1.5);
    padding-bottom: var(--padding-vertical-title-container);
    border-radius: var(--border-radius-general-elements);
    backdrop-filter: var(--blur);
    background-color: var(--background-colour-bright-transparent);



}

.main-lockup-button {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    border-color: transparent;
    background-color: var(--primary-color);
    padding-left: var(--button-about-me-horizontal-padding);
    padding-right: var(--button-about-me-horizontal-padding);
    padding-top: var(--button-about-me-vertical-padding);
    padding-bottom: var(--button-about-me-vertical-padding);
    /*  margin-left: calc(0rem - var(--horizontal-padding-main-lockup-button)); */
    color: var(--font-color-white-primary);
    font-family: var(--main-font-family);
    font-size: var(--font-size-secondary-elements);
    text-align: center;
    cursor: pointer;
    width: fit-content;
    border-radius: var(--border-radius-button);
}

.about-me-text-container h1 {
    font-size: 4vw;
    line-height: 0.1;
    text-transform: uppercase;
    cursor: pointer;
}

.about-me-text-container h2 {
    margin-top: .2vw;
    font-size: 1.55vw;
}

.about-me-text-container>div {
    font-family: var(--main-font-family);
    font-size: var(--font-size-secondary-elements);
    line-height: 150%;
    overflow-x: initial;
    overflow-y: scroll;
    height: 40vh;
    text-align: justify;
}

.about-me-text-container p {
    text-indent: 3vw;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
}

.about-me-text-container> ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
}

.about-me-text-container> ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 3px;
}

.about-me-text-container:hover ::-webkit-scrollbar {
    background: rgba(255, 255, 255, .2);
}

.about-me-text-container:hover ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
}

.about-me-image-container {
    height: calc(100vh - var(--nav-bar-height));
    position: absolute;
    bottom: 0%;
    right: 0%;
}

.about-me-image-container-mobile {
    display: none;
}

.about-me-image-container img {
    height: 95%;
    position: relative;
    top: calc(100vh - var(--nav-bar-height));
    transform: translateY(-100%);
}

.paralax-container {
    min-height: 50vh;
    box-shadow:
        inset 0px 11px 8px -10px rgba(0, 0, 0, .2),
        inset 0px -11px 8px -10px rgba(0, 0, 0, .2);
    overflow: hidden;
    background-color: rgba(0, 0, 0, .2);
}



.paralax-text-container {
    position: relative;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15vh;
    margin-bottom: 10vh;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: var(--border-radius-general-elements);
    backdrop-filter: var(--blur);
    background-color: var(--background-colour-bright-transparent);

}

.paralax-text-container h2 {
    position: relative;
    text-align: center;
    font-size: 10vw;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: var(--font-color-dark-primary);
}

.paralax-container img {
    /* Set a specific height */
    height: 100vh;
    min-width: 100%;
    filter: blur(4px);
    /* Create the parallax scrolling effect */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -3;
    object-fit: cover;
    object-position: bottom;
}

.about-me-more-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 6%;
    margin-right: 6%;
    padding-bottom: 10rem;
    gap: 2rem;
}

.about-me-more-element {
    left: 3.5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: var(--font-color-dark-primary);
    font-family: 'inter', sans-serif;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    gap: 1rem;
    border-radius: var(--border-radius-general-elements);
    backdrop-filter: var(--blur);
    background-color: var(--background-colour-bright-transparent);
}

.about-me-more-element h2 {
    font-size: 1.5vw;
}

.about-me-more-element p {
    font-family: var(--main-font-family);
    font-size: var(--font-size-secondary-elements);
    line-height: 150%;
}

@media (max-width: 1100px) {

    .about-me-mobile-container {
        display: grid;
        gap: 10vw;
        margin-top: var(--nav-bar-height);
        background-color: rgba(0, 0, 0, .2);
    }

    .about-me-image-container-mobile {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 25vh;
        position: relative;
        z-index: 1;
    }

    .about-me-image-container-mobile img {
        max-width: 100%;
        max-height: 100%;
    }

    .about-me-text-container-mobile {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 86%;
        font-family: 'inter', sans-serif;
        display: grid;
        gap: var(--gap-title-container);
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-radius: var(--border-radius-general-elements);
        backdrop-filter: var(--blur);
        background-color: var(--background-colour-bright-transparent);
    }

    .about-me-text-container-mobile h1 {
        font-size: 6vw;
        text-transform: uppercase;
        cursor: pointer;

    }

    .about-me-text-container-mobile h2 {
        margin-top: .2vw;
        font-size: 2.4vw;
    }

    .about-me-text-container-mobile p {
        font-size: 2.5vw;
        line-height: 150%;
        text-indent: 3vw;
        margin-bottom: 1rem;
        text-align: justify;
    }

    .about-me-main-container {
        display: none;
    }

    .paralax-container {
        min-height: 50vh;
        box-shadow: none;
        overflow: hidden;
        background-color: rgba(0, 0, 0, .2);
    }


}


@media (max-width: 480px) {

    .about-me-mobile-container {
        padding-bottom: 6rem;
    }

    .about-me-text-container-mobile p {
        font-size: 4vw;
        line-height: 150%;
        text-align: initial;
        text-indent: 6vw;
    }

    .paralax-text-container {
        height: auto;
        padding-top: 5vw;
        padding-bottom: 5vw;
        width: 86vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin: 0%;
        margin-top: 10%;
        margin-bottom: 10vw;
    }

    .paralax-text-container h2 {
        font-size: 15vw;
    }

    .about-me-more-container {
        grid-template-columns: 1fr;
        padding-bottom: 3rem;
        gap: 10vw;
    }

    .about-me-more-element h2 {
        font-size: 6vw;
    }

    .about-me-more-element p {
        font-size: 4vw;
        line-height: 150%;

    }
}