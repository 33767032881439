@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400&display=swap');

:root {
    --nav-bar-height: 4rem;
    --padding-horizontal-nav-elements: 1rem;
    --margin-horizontal-nav-elements: 1rem;
    --button-border-radius: .5rem;
    --nav-bar-font-family: 'Figtree', sans-serif;
    --nav-bar-font-size: 1.4rem;
    --nav-grid-gap: 2.25rem
}

nav.main-nav {
    z-index: 3;
    position: fixed;
    top: 0;
    font-family: var(--nav-bar-font-family);
    font-size: var(--nav-bar-font-size);
    color: var(--light-font);
    text-decoration: none;
    width: 100%;
    height: var(--nav-bar-height);
    background-color: var(--background-colour-bright-transparent);
    backdrop-filter: var(--blur);
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .10);
}

.nav-dummy {
    height: var(--nav-bar-height);
}

.nav-container {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 84%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.nav-container button {
    text-decoration: none;
    color: var(--font-color-dark-primary);
    font-family: var(--nav-bar-font-family);
    border-radius: var(--border-radius-button);
    align-items: center;
    border-color: transparent;
    background-color: transparent;
    display: inline-block;
    font-size: var(--font-size-secondary-elements);
    padding-left: 0;
    padding-right: 0;
}

.nav-container div {
    display: flex;
    height: 80%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    border-radius: var(--button-border-radius);
}

.nav-container .nav-right {
    display: grid;
    grid-auto-flow: column;
    gap: var(--nav-grid-gap);
}



.nav-container button:hover {
    transition: 20ms;
    background-color: rgba(0, 0, 0, 0.1);

}

.nav-left {
    width: 12rem;
}

.nav-left>*>img {
    height: 100%;
    width: 100%;
}

.nav-container .nav-right-mobile {
    display: none;
}

.nav-left button:hover {
    margin-left: calc(0rem - var(--padding-horizontal-nav-elements));
    margin-right: calc(0rem - var(--padding-horizontal-nav-elements));
    padding-left: var(--padding-horizontal-nav-elements);
    padding-right: var(--padding-horizontal-nav-elements);
}

.nav-right button:hover {
    margin-left: calc(0rem - var(--padding-horizontal-nav-elements));
    margin-right: calc(0rem - var(--padding-horizontal-nav-elements));
    padding-left: var(--padding-horizontal-nav-elements);
    padding-right: var(--padding-horizontal-nav-elements);
}

@media (max-width: 1100px) {
    .nav-container button {
        font-size: 1.2rem;
    }
}

@media (max-width: 480px) {
    .nav-container button {
        font-size: 1.2rem;
    }

    .nav-container .nav-right {
        display: none;
    }

    .nav-container .nav-right-mobile {
        display: grid;
    }

    .offcanvas-side-menu {
        background-color: var(--background-colour-bright-transparent) !important;
        backdrop-filter: var(--blur) !important;
    }

    .offcanvas-body-side-manu {
        padding-right: 0% !important;
        padding-left: 0% !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .offcanvas-side-menu button {
        text-decoration: none;
        color: var(--font-color-dark-primary);
        font-family: var(--nav-bar-font-family);
        border-radius: var(--border-radius-button);
        align-items: center;
        border-color: transparent;
        background-color: transparent;
        display: inline-block;
        font-size: 2rem;
        padding-left: 0;
        padding-right: 0;
    }

    .line-mid-side-menu {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        width: 90%;

    }


    #copyrights-side-menu {

        font-size: 1rem;
        text-align: center;

    }
}